import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]


document.addEventListener('DOMContentLoaded',  () => {
    const selectors = document.querySelectorAll('.js-work-intro_selector')
    selectors.forEach(i => i.addEventListener('click', (e) => {
            let id = i.getAttribute('data-tab')
            document.querySelectorAll('.js-work-contents').forEach(ii => ii.classList.remove('is-active'))
            document.querySelector('.js-work-intro').style="display: none"
            document.querySelector(`#${id}`).classList.add('is-active')
            
            gsap.fromTo(`#${id} .work-contents_item`, {opacity: 0, x: -10}, {duration: 0.6, x: 0, opacity: 1, stagger: 0.2, ease: Power4.easeOut})
        })
    )
    const closeWork = document.querySelectorAll('.js-close-work')
    closeWork.forEach(i => {
        i.addEventListener('click', () => {
            document.querySelectorAll('.js-work-contents').forEach(ii => ii.classList.remove('is-active'))
            document.querySelector('.js-work-intro').style="display: flex"
        })
       
    })
    
})