document.addEventListener('DOMContentLoaded',  () => {
    if(document.querySelector('.home_map')){
        const svgNations = document.querySelectorAll('.js-svg-nation')
        const lnkNations = document.querySelectorAll('.js-open-nation')

        svgNations.forEach(i => {
            let child = i.firstElementChild
            i.addEventListener('click', () => {
                
                let url = i.getAttribute('data-url')
                console.log(url)
                window.location.href = url
            })
            i.addEventListener('mouseenter', (e) => {
                lnkNations.forEach(ii => {
                    ii.classList.remove('is-hover')
                })
                let id = i.getAttribute('id')
                document.querySelectorAll(`[data-nation="${id}"]`).forEach(
                    iii => {
                        iii.classList.add('is-hover')
                    }
                )
            })
            i.addEventListener('mouseout', () => {
                lnkNations.forEach(ii => {
                    ii.classList.remove('is-hover')
                })
            })
        })

        lnkNations.forEach(i => {
            i.addEventListener('mouseenter', (e) => {
                let iid = i.getAttribute('data-nation')
                console.log(i, iid)
                document.querySelector(`#${iid}`).classList.add('is-hover')
            })
            i.addEventListener('mouseout', (ii) => {
                svgNations.forEach(ii => {
                    ii.classList.remove('is-hover')
                })
            })
        })
    }
})