// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")
require('components/work')
require('components/map')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
import fadeInScroll from "../components/fade-in-scroll.js"

import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'


const FADEINTIME = 1;
document.addEventListener('DOMContentLoaded', ()=> {
	gsap.to(['body','html'], FADEINTIME, {opacity: 1, ease: Power4.easeInOut} )
    fadeInScroll()
	watchForHover()
    isSafari();
    toggleMenu();  

    /// MASONRY FOR NEWS ///
    
    let newslist = document.querySelector('.news-list')
    if (newslist){
        imagesLoaded('body', () => {
            let msnry = new Masonry( newslist, {
                columnWidth: '.news-list_sizer',
                gutter: '.news-list_gutter',
                itemSelector: '.news-list_item',
                percentPosition: true
            })
        // msnry.once( 'layoutComplete', animationRestaurants(delay) );
        })
    }

    researchLinkPreset()
  
})

function isSafari() {
    var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    if (isSafari) { 
        document.querySelector('body').classList.add('is-safari')
    };
}

function researchLinkPreset(){
    if(document.querySelector('.researchers-index')){
        if(window.location.hash){
            console.log(window.location.hash)
        }
    }
}

function watchForHover() {
    // lastTouchTime is used for ignoring emulated mousemove events
    let lastTouchTime = 0

    function enableHover() {
        if (new Date() - lastTouchTime < 500) return
            document.body.classList.add('hasHover')
    }

    function disableHover() {
        document.body.classList.remove('hasHover')
    }

    function updateLastTouchTime() {
        lastTouchTime = new Date()
    }

    document.addEventListener('touchstart', updateLastTouchTime, true)
    document.addEventListener('touchstart', disableHover, true)
    document.addEventListener('mousemove', enableHover, true)

    enableHover()
}

function toggleMenu(){
    document.querySelector('.js-toggle-menu').addEventListener('click', () => {
      document.querySelector('.burger').classList.toggle('is-active')
      document.querySelector('.menu-mobile').classList.toggle('is-active')
      document.querySelector('.header').classList.toggle('menu-active')
      document.querySelector('body, html').classList.toggle('noscroll')
    })
  }